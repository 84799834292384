import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faLinkedin,
  faBitbucket,
} from "@fortawesome/free-brands-svg-icons";
import * as styles from "../styles/header.css";

const linkStyles = {
  target: "_blank",
  rel: "noopener noreferrer",
};

const links = [
  {
    site: "linkedin",
    endpoint: "https://www.linkedin.com/in/francis-w-37ba96166/",
    icon: faLinkedin,
  },
  {
    site: "github",
    endpoint: "https://github.com/franciswebb1?tab=repositories",
    icon: faGithub,
  },
  {
    site: "bitbucket",
    endpoint:
      "https://bitbucket.org/%7Ba2a7fb83-38e4-44a9-8343-0c4991a1aee7%7D/",
    icon: faBitbucket,
  },
];

function extractBackgroundColour() {
  if (document) {
    const colour = getComputedStyle(document.body).getPropertyValue(
      "--theme-l4"
    );
    return colour.match(/\d+/g);
  }
  return [0, 0, 0];
}

function Header() {
  const [rgb, setRgb] = useState(() => [0, 0, 0]);
  const [opacity, setOpacity] = useState(1);
  useEffect(() => {
    setRgb(extractBackgroundColour());
  }, []);
  useEffect(() => {
    window.onscroll = () => {
      const newScrollHeight = Math.ceil(window.scrollY / 50) * 50;
      if (opacity !== newScrollHeight) {
        setOpacity(Math.min(100 / newScrollHeight, 1));
      }
    };
  });
  const linkItems = links.map((link) => (
    <div key={link.site} className="padding-right-15">
      <a href={link.endpoint} target={linkStyles.target} rel={linkStyles.rel}>
        <FontAwesomeIcon icon={link.icon} />
      </a>
    </div>
  ));
  return (
    <div
      className="header"
      styles={styles}
      style={{
        backgroundColor: `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`,
      }}
    >
      <div className="header-title">francis webb</div>
      <div className="header-links">{linkItems}</div>
    </div>
  );
}

export default Header;
